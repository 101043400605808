import './main.scss'

import * as THREE from 'three'
import { BloomEffect, EffectComposer, ShaderPass, EffectPass, RenderPass } from "postprocessing"
import { gsap } from 'gsap' // https://greensock.com/docs/
import luge from '@waaark/luge' // tu connais luge ?

import Scene from '../static/js/Scene'
import Mouse from '../static/js/Mouse'
import Images from '../static/js/Images'
import Raycaster from '../static/js/Raycaster'
import Control from '../static/js/Control'
import Settings from '../static/js/Settings.js'
import Particles from '../static/js/Particles.js'
import * as dat from "dat.gui"

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    // Mobile
} else {
    // Desktop
}

const scene = new Scene({
    canvas: document.querySelector('.webgl'),
})

// const particles = new Particles({
//     scene: scene
// })

const mouse = new Mouse({
    scene: scene
})

const raycaster = new Raycaster({
    scene: scene,
    mouse: mouse.mouseScene
})

let images
setTimeout(() => {
    images = new Images({
        scene: scene,
    })
}, 1);

// const control = new Control({
//     camera: scene.camera,
//     renderer: scene.renderer
// })
let isHovering = false
let isClickable = false

document.addEventListener('click', () => {
    isHovering = true
})

console.log(scene.scene)

function raf() {
    const elapsedTime = scene.clock.getElapsedTime()

    // Update particles
    // particles.update(elapsedTime)

    if (images) {
        images.meshStorage.forEach((mesh, i) => {
            images.setPositions(mesh, images.imageStorage[i])
            mesh.material.uniforms.uTime.value = elapsedTime * 0.05
        })

        raycaster.update()

        const intersects = raycaster.raycaster.intersectObjects(images.meshStorage)

        for (const intersect of intersects) {
             if(isHovering) gsap.to(intersect.object.material.uniforms.uProgress, 3.5, { value: 1.05, ease: 'power3.out'})
        }
        isHovering = false
    }

    scene.renderer.render(scene.scene, scene.camera)

    // Update controls
    // control.controls.update()
    window.requestAnimationFrame(raf)
}

raf()
