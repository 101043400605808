import * as THREE from 'three'

class Raycaster {
   constructor(opt) {
      this.camera = opt.scene.camera
      this.mouse = opt.mouse

      this.init()
   }
   
   init() {
      this.raycaster = new THREE.Raycaster()

      this.raycaster.params.Points.threshold = .1 // taille du rayon
   }
   
   update() {
      this.raycaster.setFromCamera(this.mouse, this.camera)
   }

}

export default Raycaster