import * as THREE from 'three'
import vertex from '../glsl/vertex.vert'
import fragment from '../glsl/fragment.frag'

class Images {
   constructor(opt) {
      this.scene = opt.scene
      this.meshStorage = []
      this.imageStorage = []

      this.init()
      // this.resize()
   }

   init() {

      this.images = [...document.querySelectorAll('.img_face')]
      const imageBg = document.querySelector('.img_bg')
      const imageNoise = document.querySelector('.img_noise')

      this.loader = new THREE.TextureLoader()

      this.imgBg = this.loader.load(imageBg.src)
      this.imgNoise = this.loader.load(imageNoise.src)

      this.images.map((imgD) => {
         const imgT = this.loader.load(imgD.src)
         const image = { imageDom: imgD, imageTexture: imgT }
         this.addImage(image)
      })

   }

   addImage(image) {
      const width = image.imageDom.getBoundingClientRect().width
      const height = image.imageDom.getBoundingClientRect().height

      this.planeGeometry = new THREE.PlaneBufferGeometry(1, 1, 30, 30)
      this.planeMaterial = new THREE.ShaderMaterial({
         vertexShader: vertex,
         fragmentShader: fragment,
         uniforms: {
            uTime: { value: 0 },
            uFaceTexture: { value: image.imageTexture },
            uNoiseTexture: { value: this.imgNoise },
            uBgTexture: { value: this.imgBg },
            uProgress: { value: -0.1 },

         },
      })


      this.planeMesh = new THREE.Mesh(this.planeGeometry, this.planeMaterial)

      this.planeMesh.scale.set(width, height, 1)

      this.setPositions(this.planeMesh, image.imageDom)

      this.meshStorage.push(this.planeMesh)
      this.imageStorage.push(image.imageDom)

      this.scene.scene.add(this.planeMesh)
   }

   setPositions(mesh, img) {

      const x = img.getBoundingClientRect().left - this.scene.sizes.width / 2 + img.getBoundingClientRect().width / 2
      const y = -img.getBoundingClientRect().top + this.scene.sizes.height / 2 - img.getBoundingClientRect().height / 2

      mesh.position.set(x, y, 0)
   }

   // resize() {
   //    window.addEventListener('resize', () => {
   //       // this.planeMaterial.uniforms.uAspect.value = new THREE.Vector2(this.scene.sizes.width, this.scene.sizes.height)
   //       // this.planeMaterial.uniforms.uPixelRatio.value = window.devicePixelRatio

   //       // this.meshStorage.forEach( (mesh, i) => {
   //       //    const width = this.imageStorage[i].getBoundingClientRect().width
   //       //    const height = this.imageStorage[i].getBoundingClientRect().height
   //       //    mesh.scale.set(width, height, 1)
   //       // })
   //    })
   // }
}

export default Images